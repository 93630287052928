import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServerService } from 'src/app/server.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {
  buttonName: string;
  addUserList: any;
  editUserList: any;
  viewUserList: any;
  signupForm: FormGroup;
  userid: any;
  userObj: any={};
  countryArr: any=[];
  statesArr: any=[];
  categoryList: any=[];
  selectedCategoryArr: any=[];

  constructor( public router: Router, public service: ServerService,public route: ActivatedRoute,) { 
    let url = window.location.href;
    this.contactForm()

    if(url.includes('add-user')){
      this.buttonName = 'add-user'
     
    }else if(url.includes('edit-user')){
      this.buttonName = 'edit-user'
      this.getId()
      
    }else if(url.includes('view-user')){
      this.buttonName = 'view-user'
      this.getId()
      
    }
  }

  ngOnInit() {
   this.getCountryList()
   this.getCategory()
  }
  onCategoryChange(val) {
    let index = this.selectedCategoryArr.findIndex(x=>x === val)
    if(index == -1 && val) {
      this.selectedCategoryArr.push(val)
    }else {
      this.service.toasterErr('Item Already Present!')
    }
    this.signupForm.patchValue({
      'category': ''
    })
  }

  removeItem(index) {
    if(this.buttonName == 'view-user'){
      return;
    }
    this.selectedCategoryArr.splice(index,1)
  }
  contactForm(){
    this.signupForm = new FormGroup({
      'firstName':  new FormControl('', Validators.compose([ Validators.required, Validators.minLength(2), Validators.pattern(/^[^\s][a-zA-Z]*$/) ])),
      'lastName': new FormControl('', Validators.compose([ Validators.required, Validators.minLength(2), Validators.pattern(/^[^\s][a-zA-Z\s]*$/) ])),
      'gender': new FormControl('', Validators.required),
      'history': new FormControl('', Validators.required),
      'state': new FormControl('', Validators.required),
      'city': new FormControl('', Validators.required),
      'category': new FormControl('', Validators.required),
      'countryCode': new FormControl('', Validators.required),
      // 'email': new FormControl('', Validators.compose([ Validators.required, Validators.pattern(/^[A-Z0-9_-]+([\.][A-Z0-9_]+)*@[A-Z0-9-]+(\.[a-zA-Z]{2,3})+$/i) ])),
      'mobileNumber': new FormControl( '', Validators.compose([Validators.required, Validators.pattern(/^[1-9][0-9]{7,15}$/)])),
      'country': new FormControl( '', Validators.required ),
      'participantId': new FormControl( '', Validators.required ),
      'facilityName': new FormControl( '', Validators.required ),
    })
    console.log(this.signupForm)
   }
  //-------------ADD USER------------------//
  addUser(){
    let data ={
      "firstName": this.signupForm.get('firstName').value,
      "lastName":this.signupForm.get('lastName').value,
      "countryCode": this.signupForm.get('countryCode').value,
      "mobileNumber": this.signupForm.get('mobileNumber').value,
      // "email": this.signupForm.get('email').value,
      "history": this.signupForm.value.history,
      "gender": this.signupForm.value.gender,
      "state": this.signupForm.value.state,
      "city": this.signupForm.value.city,
      "categories": this.selectedCategoryArr,
      "participantId": this.signupForm.value.participantId,
      "facilityName": this.signupForm.value.facilityName
    }
    this.service.showSpinner()
    this.service.postApi(`api/v1/admin/addUser`,data).subscribe(res=>{
      this.service.hideSpinner()
      if(res.responseCode == 200){
        this.userid = res.result._id
        this.router.navigate(['user-management']);
        this.service.toasterSucc(res.responseMessage)
      }else{
        this.service.toasterErr(res.responseMessage)
      }
    },err=>{
      this.service.hideSpinner()
      if(err.responseCode == 400 || err.responseCode == 409){
      }
    })
  }
  //--------------EDIT USER ---------------//
  editUser(){
    let data ={
      "userId": this.userid,
      "firstName": this.signupForm.value.firstName,
      "lastName":  this.signupForm.value.lastName,
      "countryCode":  this.signupForm.value.countryCode,
      "mobileNumber":  this.signupForm.value.mobileNumber,
      // "email":  this.signupForm.value.email,
      "history": this.signupForm.value.history,
      "gender": this.signupForm.value.gender,
      "state": this.signupForm.value.state,
      "city": this.signupForm.value.city,
      "categories": this.selectedCategoryArr,
      "participantId": this.signupForm.value.participantId,
      "facilityName": this.signupForm.value.facilityName
    }
    this.service.showSpinner()
    this.service.put(`api/v1/admin/editUser`,data).subscribe(res=>{
      this.service.hideSpinner()
      if(res['responseCode'] == 200){
        this.editUserList = res['result']
        this.router.navigate(['user-management']);
        this.service.toasterSucc(res['responseMessage'])
      }else {
        this.service.toasterErr(res['responseMessage'])
      }
    },err=>{
      this.service.hideSpinner()
      if(err['responseCode'] == 400 || err['responseCode'] == 409){
        this.service.toasterErr(err.error['responseMessage'])
      }
    })

  }

  get formC() {
    return this.signupForm.controls
  }
  getId(){
    this.route.params.subscribe(res=>{
      this.userid = res.id
      this.getContactById()
    })
  }
  
getContactById(){
  this.service.showSpinner()
  this.service.get(`api/v1/admin/viewUser/${this.userid}`).subscribe(res=>{
    this.service.hideSpinner()
    if(res['responseCode'] == 200){
      this.userObj = res['result']
      this.service.toasterSucc(res.responseMessage)
      this.patchForm();      
    }
  },err=>{
    if(err.responseCode == 400){
      this.service.hideSpinner()
      this.service.toasterErr(err.error.responseMessage)
    }
  })
}
//-----------TO PATCH FORM DETAIL -----------------------//
patchForm(){
  if(this.buttonName == 'view-user'){
    this.signupForm.disable()
  }
  this.signupForm.patchValue({
    'firstName':this.userObj.firstName ,
    'lastName':this.userObj.lastName,
    'countryCode':this.userObj.countryCode,
    // 'email':this.userObj.email,
    'mobileNumber': this.userObj.mobileNumber,
    'history': this.userObj.history,
    'gender': this.userObj.gender,
    'city':this.userObj.city,
    'participantId':this.userObj.participantId,
    'facilityName':this.userObj.facilityName,
    
  })
  this.selectedCategoryArr = this.userObj.categories
  if(this.userObj.countryCode){
    this.getStates(this.userObj.countryCode);
  }
}
getCountryList(){
  this.service.getJson().subscribe(res=>{
    this.countryArr = res;
    this.signupForm.patchValue({
      'countryCode': '+91'
    })
    this.getStates('+91')
  })
}

getStates(value) {
  let obj = this.countryArr.find(x=>x.code == value)
  console.log(obj)
  this.statesArr = obj.states;
    this.signupForm.patchValue({
      'state': this.userObj.state ? this.userObj.state : ''
    })
  console.log(this.statesArr)
}
getCategory(){
  this.service.get(`api/v1/admin/allCategoryList`).subscribe(res=>{
    if(res.responseCode == 200){
      this.categoryList = res.result
    }
  },err=>{})
}
}
