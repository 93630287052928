import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';


@Injectable({
  providedIn: 'root'
})
export class ServerService {
  loginSub = new BehaviorSubject(``);
  loginObs = this.loginSub.asObservable();
  code: string;
  httpOptions: { headers: HttpHeaders; };
  // public baseUrl = "http://182.72.203.245:1821/"

//   public baseUrl = "http://43.242.214.226:1821/"
  // public websiteURL = "https://phfi-node.mobiloitte.com/"
  public websiteURL = "http://43.242.214.226:1821/"
    public baseUrl = "https://phfi-node.mobiloitte.com/"
  // public websiteURL = "http://localhost:4200/reset-password"

  constructor(public http: HttpClient, private toastr: ToastrService, private spinner: NgxSpinnerService, public routes: Router) { }

  changeLoginSub(msg) {
    this.loginSub.next(msg);
  }
  // Api Functionlity 
  // Api Structuring Functionality
  post(url, data) {
   
    if (localStorage.getItem('Auth')) {
      this.httpOptions = {
        headers: new HttpHeaders({ 'token':localStorage.getItem('Auth') })
      };
    }
    return this.http.post(this.baseUrl + url, data,this.httpOptions);
  }

  deleteApi(url) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "token": localStorage.getItem('Auth')
      }), observe: 'response'
    }
    return this.http.delete(this.baseUrl + url, httpOptions);
  }
 
  put(url,data){
    
    if (localStorage.getItem('data') || localStorage.getItem('Auth')) {
      this.httpOptions = {
        headers: new HttpHeaders({'token': localStorage.getItem('Auth')})
      };
    }
    console.log(this.httpOptions)
    return this.http.put(this.baseUrl + url, data, this.httpOptions);
  }

 
  get(url) :Observable<any> {
  
    if (localStorage.getItem('data') || localStorage.getItem('Auth')) {
      this.httpOptions = {
        headers: new HttpHeaders({ 'token': localStorage.getItem('Auth') })
      }
    }
    return this.http.get(this.baseUrl + url, this.httpOptions);
  }
 

  getThirdPartyApi(url) {
    return this.http.get(url,{observe:'response'})
  }

  //-----------------JSON---------------
  getJson(){
    return this.http.get('assets/json/country.json')
  }

  // Form Data Api Structure
  postApi(endPoint, data): Observable<any> {
    if (localStorage.getItem('Auth')) {
      this.code = localStorage.getItem('Auth')
    }
    if (localStorage.getItem('data') || localStorage.getItem('Auth')) {

      this.httpOptions = {
        headers: new HttpHeaders({ 'token': `${this.code}` })
      }
    }
    return this.http.post(this.baseUrl + endPoint, data, this.httpOptions);
  }




  // Spinner 
  showSpinner() {
    this.spinner.show();
  }

  hideSpinner() {
    this.spinner.hide();
  }

  // Toaster Functionality
  toasterSucc(msg) {
    this.toastr.success(msg)
  }
  toasterErr(msg) {
    this.toastr.error(msg)
  }
  toasterInfo(msg){
    this.toastr.info(msg)
  }

   // Logout
   onLogout() {
    localStorage.clear();
    // window.location.reload();
    this.routes.navigate(['/login']);
    // $('#signout_modal').modal('hide');
  }

    //Export
    public exportAsExcelFile(json: any[], excelFileName: string): void {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
  
      const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
      this.saveAsExcelFile(excelBuffer, excelFileName);
    }
  
    private saveAsExcelFile(buffer: any, fileName: string): void {
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }
  
}
