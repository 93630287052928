import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServerService } from 'src/app/server.service';

@Component({
  selector: 'app-schedule-message-new',
  templateUrl: './schedule-message-new.component.html',
  styleUrls: ['./schedule-message-new.component.css']
})
export class ScheduleMessageNewComponent implements OnInit {
  buttonName: any;
  ScheduleMessageForm: FormGroup;
  addMessageList: any;
  id: any;
  viewMessageList: any;
  ckeditorContent: any
  soundUrl: any;
  fromDate: string;
  userObj: any={};
  editId: any;
  type: any;
  categoryList: any=[];
  selectedCategoryArr: any=[];
  maxFromDate: string;
  maxToDate: string;
  toDate: string;
  minToDate: string;
  messageCount:any;

  constructor(public router: Router, public service: ServerService,private active: ActivatedRoute) {
    let url = window.location.href;
    this.contactForm()
    if(url.includes('add-new-message')){
      this.buttonName = 'add-new-message'
    }else if(url.includes('edit-new-message')){
      this.buttonName = 'edit-new-message' 
      this.viewNewMessage()
    }else if(url.includes('view-new-message')){
      this.buttonName = 'view-new-message'
          
    }
    this.active.params.subscribe(x=>{
      this.id= x['id'];
      this.editId= x['id']
      console.log(this.id)
    })
  }

  ngOnInit() {
    this.viewNewMessage() 
    this.getCategory()
    this.minToDate = new Date(Number(new Date())+19800000).toISOString().slice(0,16)
    console.log(this.minToDate)
    // this.minToDate = new Date().toLocaleDateString()
  }
  get DescriptionLength(){
    document.getElementById("demo").innerHTML = this.ScheduleMessageForm.value.text
    return document.getElementById("demo").textContent.length
  }


  onFromChangeDate() {
    this.minToDate = new Date().toISOString().slice(0,16)
  }
  
  onCategoryChange(val) {
    console.log(val)
    let index = this.selectedCategoryArr.findIndex(x=>x === val)
    console.log(index)
    if(index == -1 && val) {
      this.selectedCategoryArr.push(val)
    }else {
      this.service.toasterErr('Item Already Present!')
    }
    this.ScheduleMessageForm.patchValue({
      'category': ''
    })

  }

  
  removeItem(index) {
    if(this.buttonName == 'view-new-message'){
      return;
    }
    this.selectedCategoryArr.splice(index,1)
  }

  contactForm(){
    this.ScheduleMessageForm = new FormGroup({
      'dateAndTime': new FormControl('', Validators.required),
      'text': new FormControl('', Validators.required),
      'category': new FormControl('', Validators.required),
      'audio': new FormControl('', Validators.required)
    })
    
   }

   addNewMessage(){
    document.getElementById("demo").innerHTML = this.ScheduleMessageForm.value.text;
   let data= {
      "dateAndTime": new Date(this.ScheduleMessageForm.value.dateAndTime).getTime(),
      "text": document.getElementById("demo").textContent,
      "file":this.soundUrl,
      "categories":this.selectedCategoryArr
    }
    this.service.showSpinner()
    this.service.postApi(`api/v1/admin/addNewMessage`,data).subscribe(res=>{
      this.service.hideSpinner()
      if(res.responseCode == 200){
        this.addMessageList = res.result
        this.router.navigate(['schedule-message']);
        this.service.toasterSucc(res.responseMessage)
      }else {
        this.service.toasterErr(res['responseMessage'])
      } 
    },err=>{
      this.service.hideSpinner()
      if(err.responseCode == 400){
        this.service.toasterErr(err.responseMessage)
      }
    })
   }
   editNewMessage(){
    document.getElementById("demo").innerHTML = this.ScheduleMessageForm.value.text;

   let data= {
     "_id":this.editId,
      "dateAndTime": new Date(this.ScheduleMessageForm.value.dateAndTime).getTime(),
      "text": document.getElementById("demo").textContent,
      "file":this.soundUrl,
      "categories": this.selectedCategoryArr
    }
    this.service.put(`api/v1/admin/editMessage`,data).subscribe(res=>{
      if(res['responseCode'] == 200){
        this.addMessageList = res['result']
        this.router.navigate(['schedule-message']);
        this.service.toasterSucc(res['responseMessage'])
      }else {
        this.service.toasterErr(res['responseMessage'])
      } 
    },err=>{
      this.service.hideSpinner()
      if(err.responseCode == 400){
        this.service.toasterErr(err.responseMessage)
      }
    })
   }

   viewNewMessage(){
     this.service.get(`api/v1/admin/viewMessage/`+this.id).subscribe(res=>{
       if(res.responseCode == 200){
         this.userObj = res.result
         this.patchForm(); 
       }

     },err=>{

     })
   }

  uploadAudio(event) {
    let fileList=  event.target.files  || event.target.files[0]
    console.log(fileList)
    let file: File = fileList[0];
    console.log(file,'file')
    if(file.type == 'application/pdf'){
      this.service.toasterErr('PDF is not supported!')
      this.ScheduleMessageForm.patchValue({
        'audio': null
      })
      return;
    }
    let formdata = new FormData();
    formdata.append('file', file, file.name);
    this.service.showSpinner()
    this.service.postApi(`api/v1/admin/uploadFile`,formdata).subscribe(res=>{
      this.service.hideSpinner()
      if(res.responseCode == 200){
        this.soundUrl = res.result;
        this.type = file.type.split('/')[0]
      }else {
        this.service.toasterErr(res.responseMessage)
        this.ScheduleMessageForm.patchValue({'audio': null})
      }

    },err=>{
      this.service.hideSpinner()
      this.ScheduleMessageForm.patchValue({'audio': null})
    })
  }
  
  //-----------TO PATCH FORM DETAIL -----------------------//
patchForm(){
  if(this.buttonName == 'view-new-message'){
    this.ScheduleMessageForm.disable()
  }
  this.ScheduleMessageForm.patchValue({
    'dateAndTime':new Date(Number(this.userObj.dateAndTime)+19800000).toISOString().slice(0,16),
    'text':this.userObj.text,
  })
  this.selectedCategoryArr = this.userObj.categories
  this.soundUrl = this.userObj.file
  if(this.soundUrl ) {
    if((this.soundUrl.includes('.jpg') || this.soundUrl.includes('.jpeg') ||this.soundUrl.includes('.jfif')||this.soundUrl.includes('.pjpeg')||this.soundUrl.includes('.pjp') || this.soundUrl.includes('.png') || this.soundUrl.includes('.gif'))){
      this.type = 'image'
      }else if(this.soundUrl.includes('.mp4')){
        this.type = 'video'
      }else if(this.soundUrl.includes('.mp3') || this.soundUrl.includes('.ogg')){
        this.type = 'audio'
      }
  }
  
}
//-----------------To category List ----------------------//
getCategory(){
  this.service.showSpinner()
  this.service.get(`api/v1/admin/allCategoryList`).subscribe(res=>{
    this.service.hideSpinner()
    if(res.responseCode == 200){
      this.categoryList = res.result
      this.service.toasterSucc(res.responseMessage)
    }else {
      this.service.toasterErr(res['responseMessage'])
    } 
  },err=>{
    this.service.hideSpinner()
    if(err.responseCode == 400){
      this.service.toasterErr(err.responseMessage);
    }
  })
}

}
