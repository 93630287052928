import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ServerService } from 'src/app/server.service';


declare var $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor( private router: Router,
    private http : HttpClient,
    public service : ServerService) { }

  ngOnInit() {
  }
  logoutYes(){
    // localStorage.removeItem('userId')
    // localStorage.removeItem('token')
    // localStorage.removeItem('data')
    // localStorage.removeItem('email')
    // this.router.navigate(['/login'])
    this.service.showSpinner()
    this.service.postApi(`api/v1/admin/logout`,{}).subscribe(res=>{
      this.service.hideSpinner()
      if(res.responseCode == 200){
        localStorage.removeItem('userId')
        localStorage.removeItem('Auth')
        localStorage.removeItem('data')
        $('#logout').modal('hide');
          this.router.navigate(['/login'])
          this.service.toasterSucc(res.responseMessage)
      }else {
        this.service.toasterErr(res['responseMessage'])
      } 
    },err=>{
      this.service.hideSpinner()
      if(err.responseCode == 400){
        this.service.toasterErr(err.error.responseMessage)
      }
    })
    }
}
