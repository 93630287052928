import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServerService } from 'src/app/server.service';

declare var $: any
@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {
  scheduleMessageForm: any;
  getCategoryList: any;
  pageNumber: any;
  userid: number;
  action: any;
  editCategory: any;
  userObj: any;
  useredit: any;
  editDiv: boolean = false; 
  totalRecord: any=0;
  limit: any=10;


  constructor(private route: Router, public service: ServerService) { }

  ngOnInit() {
    this.userForm()
    this.getCategory()
    // this.getContactById()
  }

  userForm(){
    this.scheduleMessageForm = new FormGroup({
      'categoryName': new FormControl('', Validators.required),
      'categoryEditName': new FormControl('', Validators.required),
    })
   }
   getAllCategory(){
     this.service.showSpinner()
     this.service.get(`api/v1/admin/allCategoryList`).subscribe(res=>{
       this.service.hideSpinner()
       if(res.responseCode == 200){
         this.getCategoryList = res.result
         this.service.toasterSucc(res.responseMessage)
       }else {
        this.service.toasterErr(res['responseMessage'])
      } 
     },err=>{
       this.service.hideSpinner()
       if(err.responseCode == 400){
         this.service.toasterErr(err.error.responseMessage);
         
       }
     })
   }
   getCategory(){
    this.getCategoryList=[]
    this.totalRecord = 0
    let data = {
      'page':this.pageNumber,
      'limit':this.limit
      }
     this.service.showSpinner()
     this.service.postApi(`api/v1/admin/categoryList`,data).subscribe(res=>{
       this.service.hideSpinner()
       if(res.responseCode == 200){
         this.getCategoryList = res.result.docs
         this.service.toasterSucc(res.responseMessage)
         this. totalRecord = res.result.total
        this.limit = res.result.limit
       }else {
        this.service.toasterErr(res['responseMessage'])
      } 
     },err=>{
       this.service.hideSpinner()
       if(err.responseCode == 400){
         this.service.toasterErr(err.error.responseMessage);
         
       }
     })
   }


 // ------------------------pagination -------------------------//
 pagination(page){
  this.pageNumber=page; 
  this.getCategory()   
}
 //========modal=======//
 delete(id: number) {
  this.userid = id;
  $('#deleteModal').modal('show')
}
//------------------------------delete api integration ----------------------------------//
deleteUser() {
  var url = 'api/v1/admin/deleteCategory/'+this.userid
  this.service.showSpinner()
  this.service.deleteApi(url).subscribe((res: any) => {
    this.service.hideSpinner()
    if (res.body['responseCode'] = 200) {
      $('#deleteModal').modal('hide')
      this.service.toasterSucc(res['body']['responseMessage']);
      this.getCategory()
    }else {
      this.service.toasterErr(res['body']['responseMessage'])
    } 
   }, err => {   
     this.service.hideSpinner();  
      if (err.body['responseCode'] == '401' || '400') {  
          this.service.onLogout();   
         this.service.toasterErr(err.body.error.responseMessage); 
       } 
    else {    
        this.service.toasterErr('Something Went Wrong');  
      } 
   })

}

openModal(action, userId) {
  this.userid = userId;
  this.action = action;
  if (action == 'DELETE') {
    $('#deleteModal').modal('show')

  } else if (action == 'BLOCK') {
    $('#block').modal('show')
  }
  else {
    $('#active').modal('show')
  }
}
//-------------ADD USER------------------//
addUser(){
  let data ={
    "categoryName": this.scheduleMessageForm.get('categoryName').value,
  }
  this.service.showSpinner()
  this.service.postApi(`api/v1/admin/addCategory`,data).subscribe(res=>{
    this.service.hideSpinner()
    if(res.responseCode == 200){
      this.userid = res.result._id
      this.service.toasterSucc(res.responseMessage)
      this.getCategory()
    }else {
      this.service.toasterErr(res['responseMessage'])
    } 
  },err=>{
    if(err.responseCode == 400){
      this.service.hideSpinner()
      this.service.toasterErr(err.error.message)
    }
  })
}
//--------------EDIT USER ---------------//
editUser(){
  let data ={
    "categoryId": this.useredit,
    "categoryName": this.scheduleMessageForm.value.categoryEditName,
  }
  this.service.showSpinner()
  this.service.put(`api/v1/admin/editCategory`,data).subscribe(res=>{
    this.service.hideSpinner()
    if(res['responseCode'] == 200){
      this.editCategory = res['result']
      this.editDiv = false
      this.getCategory()
      this.service.toasterSucc(res['responseMessage'])
    }else {
      this.service.toasterErr(res['responseMessage'])
    } 
  },err=>{
    if(err['responseCode'] == 400){
      this.service.hideSpinner()
      this.service.toasterErr(err.error['responseMessage'])
    }
  })

}
  
getContactById(){
  this.service.showSpinner()
  this.service.get(`api/v1/admin/viewCategory/${this.userid}`).subscribe(res=>{
    this.service.hideSpinner()
    if(res['responseCode'] == 200){
      this.userObj = res['result']
      this.service.toasterSucc(res.responseMessage)
      this.patchForm();      
    }else {
      this.service.toasterErr(res['responseMessage'])
    } 
  },err=>{
    if(err.responseCode == 400){
      this.service.hideSpinner()
      this.service.toasterErr(err.error.message)
    }
  })
}

//-----------TO PATCH FORM DETAIL -----------------------//
patchForm(){
  this.scheduleMessageForm.patchValue({
    'categoryEditName':this.userObj.categoryName
  })
  
}
edit(id){
  this.useredit = id
  this.editDiv = true
  this.service.showSpinner()
  this.service.get(`api/v1/admin/viewCategory/${this.useredit}`).subscribe(res=>{
    this.service.hideSpinner()
    if(res['responseCode'] == 200){
      this.userObj = res['result']
      this.scheduleMessageForm.patchValue({
        'categoryEditName':this.userObj.categoryName
      })
    }else {
      this.service.toasterErr(res['responseMessage'])
    } 
  },err=>{
    if(err.responseCode == 400){
      this.service.hideSpinner()
      this.service.toasterErr(err.error.message)
    }
  })
}

   
}
