import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ServerService } from 'src/app/server.service';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  buttonName: string;
  loginForm: FormGroup;
  Obj: { email: any; };
  userId: string;
  view: boolean =false;

  constructor(
    private router: Router,
    private http : HttpClient,
    public service : ServerService

  ) {
    let url = window.location.href;
    this.loginFormValidation()

    if(url.includes('login')){
      this.buttonName = 'login'
     
    }else if(url.includes('forget-password')){
      this.buttonName = 'forget-password'
      
    }else if(url.includes('reset-password')){
      this.buttonName = 'reset-password'
      let url = window.location.href;
      let arr = url.split('/')
      this.userId = arr[arr.length - 1]
    }
   }

  ngOnInit() {
   if(localStorage.getItem('Auth')) {
     localStorage.removeItem('Auth')
   }
  }

  viewPassword(type){
if(type == 'lock'){
  this.view=true
}else (
  this.view=false
)

  } 


  loginFormValidation(){
    this.loginForm= new FormGroup({
      'userId': new FormControl('',[Validators.required, Validators.pattern(/^[A-Z0-9_-]+([\.][A-Z0-9_]+)*@[A-Z0-9-]+(\.[a-zA-Z]{2,3})+$/i), Validators.maxLength(50)]),
      'password': new FormControl('',[Validators.required,Validators.pattern(/^(?=^.{8,16}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*?[#?!@$%^&*-])(?!.*\s).*$/)]),
      'confirmPassword': new FormControl('',[Validators.required]),
      'rememberMe': new FormControl('',[Validators.required]),
      'email': new FormControl('',[Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,9}|[0-9]{1,3})(\]?)$/i)]),

    })
  }
  //==========================login================================//
  login(){
    this.service.showSpinner()
   let data={
    email :this.loginForm.value.userId,
    password : this.loginForm.value.password,
    }
   
  this.service.post('api/v1/admin/login',data ).subscribe( (res : any)=>{
     console.log("res:::::", res)
     this.service.hideSpinner()
       if(res['responseCode'] == '200'){
        this.service.toasterSucc(res.responseMessage)
       localStorage.setItem('Auth',res['result']['token']);
       localStorage.setItem('userId',res['result']['userId']);
       if(this.loginForm.value.rememberMe==true){
        let remData={
          "email":this.loginForm.value.userId,
          //  "password":window.btoa(this.loginForm.value.password)
        }
        localStorage.setItem('rememberMe',JSON.stringify(remData))
    }
       this.service.changeLoginSub('login');
        this.router.navigate(['dashboard']);
       }else {
        this.service.toasterErr(res['responseMessage'])
       }
    //  else if(res['responseCode'] == '400'){
    //   this.service.toasterErr(res['responseMessage'])
    // }
    },
    (err : any)=>{
      this.service.hideSpinner();
      if(err['responseCode']=='401' || 400){
        this.service.toasterErr(err.responseMessage);
        localStorage.removeItem('Auth');
        // console.log(err)
      }else{
      this.service.toasterErr('Something Went Wrong');
   }
    }
  )
this.Obj={
 'email' : this.loginForm.value.email,

}
  localStorage.setItem('data',JSON.stringify(this.Obj));
  
  }
  //==========================forget password=======================//
  forgetPassword(){
    var url = "api/v1/admin/forgotPassword"
    let data={
      "email" : this.loginForm.value.email
     }
     this.service.showSpinner()
      this.service.post(url,data).subscribe(res=>{
        this.service.hideSpinner()
        if(res['responseCode']== 200){
          localStorage.setItem('email',JSON.stringify(this.loginForm.value.email))
          console.log(res)
          this.service.toasterSucc(res['responseMessage'])
        }
        else if(res['responseCode']==400){
          this.service.hideSpinner()
        this.service.toasterErr(res['error']['responseMessage']);
        }
      },err=>{
              
        if(err['responseCode']=='401' || '400'){
          this.service.hideSpinner()
          this.service.toasterErr(err['error']['responseMessage']);
        }else{
        this.service.toasterErr(err['error']['responseMessage']);
       }
      });
  }
  //==========================reset password========================//
  resetPassword(){
    var apireq = {
      // 'email':JSON.parse(localStorage.getItem('email')),
      'newPassword': this.loginForm.value.password,
      // 'confirmPassword': this.loginForm.value.confirmPassword
    }
    this.service.showSpinner()
    this.service.post(`api/v1/admin/resetPassword/${this.userId}`,apireq).subscribe((res : any)=>{
     this.service.hideSpinner()
      if(res['responseCode'] == 200){
        this.service.toasterSucc(res['responseMessage'])
        // this.router.navigateByUrl('/login')
        this.service.onLogout()
      }else {
        this.service.toasterErr(res['responseMessage'])
      } 
    },  (err : any)=>{
          if(err['responseCode']=='404' || '400'){
        this.service.toasterErr(err['error']['responseMessage']);
      }else{
      this.service.toasterErr('Something Went Wrong');
   }
    })
  }

}
