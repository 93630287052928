import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './component/login/login.component';
import { FooterComponent } from './component/footer/footer.component';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { UserManagementComponent } from './component/user-management/user-management.component';
import { AddUserComponent } from './component/add-user/add-user.component';
import { ScheduleMessageComponent } from './component/schedule-message/schedule-message.component';
import { ScheduleMessageNewComponent } from './component/schedule-message-new/schedule-message-new.component';
import { HeaderComponent } from './component/header/header.component';
import { PageNotFoundComponent } from './component/page-not-found/page-not-found.component';
import { CategoryComponent } from './component/category/category.component';
import { AuthService } from './auth.service';


const routes: Routes = [
  {path: '', redirectTo: 'login', pathMatch: 'full'},
  {path: 'login', component: LoginComponent},
  {path: 'forget-password', component: LoginComponent},
  {path: 'reset-password/:id', component: LoginComponent},
  {path: 'footer', component: FooterComponent},
  {
    path: 'dashboard', 
    component: DashboardComponent,
    canLoad:[AuthService],
    canActivate:[AuthService]
  },
  {
    path: 'header', 
    component: HeaderComponent
  },
  {
    path: 'user-management',
    component: UserManagementComponent,
    canLoad:[AuthService],
    canActivate:[AuthService]
  },
  {
    path: 'add-user', 
    component: AddUserComponent,
    canLoad:[AuthService],
    canActivate:[AuthService]
  },
  {
    path: 'edit-user/:id',
    component: AddUserComponent,
    canLoad:[AuthService],
    canActivate:[AuthService]
  },
  {
    path: 'view-user/:id',
    component: AddUserComponent,
    canLoad:[AuthService],
    canActivate:[AuthService]
  },
  {
    path: 'schedule-message',
    component: ScheduleMessageComponent,
    canLoad:[AuthService],
    canActivate:[AuthService]
  },
  {
    path: 'add-new-message',
    component: ScheduleMessageNewComponent,
    canLoad:[AuthService],
    canActivate:[AuthService]
  },
  {
    path: 'edit-new-message/:id',
    component: ScheduleMessageNewComponent,
    canLoad:[AuthService],
    canActivate:[AuthService]
  },
  {
    path: 'view-new-message/:id',
    component: ScheduleMessageNewComponent,
    canLoad:[AuthService],
    canActivate:[AuthService]
  },
  {
    path: 'category', 
    component: CategoryComponent,
    canLoad:[AuthService],
    canActivate:[AuthService]
  },
  {path: '**', component: PageNotFoundComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
