import { Injectable } from '@angular/core';
import { Router, CanLoad, CanActivate } from '@angular/router';
import { Route } from '@angular/compiler/src/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements CanLoad,CanActivate {

  constructor(private router:Router) { 
  
  }

  // returns false if token is expired otherwise returns true(used for loading modules)
  canLoad(route:Route): boolean {
    if(this.isTokenExpired(localStorage.getItem('Auth'))) {
      this.router.navigate([''])
    }
    return !this.isTokenExpired(localStorage.getItem('Auth'))
  }

 // returns false if token is expired otherwise returns true(used for activating routes)
 canActivate(route:Route): boolean {
    if(this.isTokenExpired(localStorage.getItem('Auth'))) {
      this.router.navigate(['login'])
    }
    return !this.isTokenExpired(localStorage.getItem('Auth'))
  }

  isTokenExpired(token):boolean {
    if(token) {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      if(jsonPayload) {
        return false;
      }else {
        // if payload is false
        return true
      }
    }else {
      // if token does not exist in sessionStorage
      return true
    }
  }
    
    
}
