import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServerService } from 'src/app/server.service';
declare var $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  dashboardList: any;

  constructor( 
    private router: Router,
    private http : HttpClient,
    public service : ServerService) { }

  ngOnInit() {
    this.getDashboard()
  }
  getDashboard(){
    this.service.showSpinner()
    this.service.get(`api/v1/admin/dashboard`).subscribe(res=>{
      this.service.hideSpinner()
      if(res.responseCode == 200){
        this.service.toasterSucc(res.responseMessage)
        this.dashboardList = res.result
      }else {
        this.service.toasterErr(res['responseMessage'])
      } 
    },err=>{
      if(err.responseCode == 400){
        this.service.toasterErr(err.error.responseMessage)
      }
    })
  }
  loginNavigate(){
    localStorage.removeItem('Auth')
    this.router.navigate(['/login'])
  }
 

}
