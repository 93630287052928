import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServerService } from 'src/app/server.service';
import { ExportToCsv } from 'export-to-csv';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';


declare var $: any
@Component({
  selector: 'app-schedule-message',
  templateUrl: './schedule-message.component.html',
  styleUrls: ['./schedule-message.component.css']
})
export class ScheduleMessageComponent implements OnInit {
  messageListArr: any;
  pageNumber: number=1;
  userid: number;
  action: any;
  scheduleMessageForm: FormGroup;
  totalRecord: any=0;
  limit: any=10;
  totalRecords: any[];


  constructor(private route: Router, public service: ServerService,public fb: FormBuilder,) { }

  ngOnInit() {
    this.userForm()
    this.messageList()
  }
  userForm(){
    this.scheduleMessageForm = this.fb.group({
      'fromDate': new FormControl('',Validators.required),
      'toDate': new FormControl('',Validators.required),
      'status': new FormControl('',Validators.required),
    })
   }

  messageList(){
    this.messageListArr = []
    this.totalRecord = 0
    let data = {
      'page':this.pageNumber,
      'limit':this.limit
      }
      this.service.showSpinner()
    this.service.postApi(`api/v1/admin/messageList`,data).subscribe(res=>{
      this.service.hideSpinner()
      if(res.responseCode == 200){
        this.service.toasterSucc(res.responseMessage)
        this.messageListArr = res.result.docs;
        this. totalRecord = res.result.total
        this.limit = res.result.limit
        this.messageListArr.map(element=>{
          if(element.file && element.file.includes('.jpg' ) || element.file && element.file.includes('.jpg') || element.file && element.file.includes('.jpeg') || element.file && element.file.includes('.jfif') || element.file && element.file.includes('.pjpeg') || element.file && element.file.includes('.pjp') || element.file && element.file.includes('.png') || element.file && element.file.includes('.gif')) {
            element['type'] = 'image'
          }else if(element.file && element.file.includes('.mp3') || element.file && element.file.includes('ogg')) {
            element['type'] = 'audio'
          }else if(element.file && element.File.includes('mp4')) {
            element['type']= 'video'
          }
        })
      }else {
        this.service.toasterErr(res['responseMessage'])
      } 
    },err=>{
      this.service.hideSpinner()
      if(err.responseCode == 400){
        this.service.toasterErr(err.responseMessage)
      }
    })
  }
   //----------------------------------export User---------------------------------//
   exportAsXLSX() {
    let dataArr = [];
    this.messageListArr.forEach((element, ind) => {
      let obj ={}
      obj={
        "S.no": ind + 1,
        "Text Message": element.text ? element.text : 'N/A',
        "categories": element.categories ? element.categories.join(',') : 'N/A',
        "Schedule Date": element.createdAt ? `${element.createdAt.split('T')[0]}  ${element.createdAt.split('T')[1]}` : 'N/A',
        "File(Image/Audio)": element.file ? element.file : 'N/A',
        "Status": element.messageStatus ? element.messageStatus : 'N/A',
      }
      dataArr.push(obj)
      console.log(obj)

    })

    this.service.exportAsExcelFile(dataArr, 'Admin User List');
  }
  // ------------------------pagination -------------------------//
  pagination(page){
    this.pageNumber=page;
    this.messageList()   
  }

   //========modal=======//
   delete(id: number) {
    this.userid = id;
    $('#deleteModal').modal('show')
  }
  //------------------------------delete api integration ----------------------------------//
  deleteUser() {
    var url = 'api/v1/admin/deleteMessage/'+this.userid
    this.service.showSpinner()
    this.service.deleteApi(url).subscribe((res: any) => {
     this.service.hideSpinner()
      if (res['responseCode'] = 200) {
        console.log(res)
        $('#deleteModal').modal('hide')
        this.service.toasterSucc(res['body']['responseMessage']);
        this. messageList()
      }else {
        this.service.toasterErr(res['body']['responseMessage'])
      }
     }, err => {  
       this.service.hideSpinner();  
        if (err['responseCode'] == '400') {  
            this.service.onLogout();   
           this.service.toasterErr('Unauthorized Access'); 
         } 
      else {    
          this.service.toasterErr('Something Went Wrong');  
        } 
     })

  }

  openModal(action, userId) {
    this.userid = userId;
    this.action = action;
    if (action == 'DELETE') {
      $('#deleteModal').modal('show')

    } else if (action == 'BLOCK') {
      $('#block').modal('show')
    }
    else {
      $('#active').modal('show')
    }
  }

  searchUser(){
    this.messageListArr=[]
    this.totalRecord = 0
    this.pageNumber = 1
    let data ={
      'search': this.scheduleMessageForm.value.status,
      // 'fromDate':new Date(this.scheduleMessageForm.value.fromDate).toISOString(),
      // 'toDate': new Date(this.scheduleMessageForm.value.toDate).toISOString()
    }
    this.service.showSpinner()
    this.service.postApi(`api/v1/admin/messageList`,data).subscribe(res=>{
      this.service.hideSpinner()
      if(res.responseCode == 200){
        this.service.toasterSucc(res.responseMessage)
        this.messageListArr = res.result.docs
        this.totalRecord = res.result.total
        this.limit = res.result.limit
      }else {
        this.service.toasterErr(res['responseMessage'])
      }
    },err=>{
      this.service.hideSpinner()
      if(err.responseCode == 400){
        this.service.toasterErr(err.responseMessage)
      }
    })
  }
  filter() {
    this.pageNumber = 1;
    this.searchUser()
  }
  resetForm(){
    this.userForm()
    this. messageList()
  }

}
