//-------------MODULES----------------//
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { NgxPaginationModule } from 'ngx-pagination'
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


//---------COMPONENT--------------------//
import { AppComponent } from './app.component';
import { LoginComponent } from './component/login/login.component';
import { FooterComponent } from './component/footer/footer.component';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { UserManagementComponent } from './component/user-management/user-management.component';
import { AddUserComponent } from './component/add-user/add-user.component';
import { ScheduleMessageComponent } from './component/schedule-message/schedule-message.component';
import { ScheduleMessageNewComponent } from './component/schedule-message-new/schedule-message-new.component';
import { HeaderComponent } from './component/header/header.component';
import { PageNotFoundComponent } from './component/page-not-found/page-not-found.component';
import { CKEditorModule } from 'ng2-ckeditor';
import { CategoryComponent } from './component/category/category.component';
import { AuthService } from './auth.service';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    FooterComponent,
    DashboardComponent,
    UserManagementComponent,
    AddUserComponent,
    ScheduleMessageComponent,
    ScheduleMessageNewComponent,
    HeaderComponent,
    PageNotFoundComponent,
    CategoryComponent,

  ],
  imports: [
    BrowserModule,
    CKEditorModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-right',
      maxOpened:1,
      preventDuplicates: true
    }),
    NgxSpinnerModule,
    NgxPaginationModule,
    BrowserModule,
    BrowserAnimationsModule
  ],
  providers: [NgxSpinnerService,AuthService],
  bootstrap: [AppComponent]
})
export class AppModule { }
