import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ServerService } from 'src/app/server.service';


declare var $: any
@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css']
})
export class UserManagementComponent implements OnInit {
  pageNumber:number=1
  userList: any;
  userid: any;
  action: any;
  userstatus: any;
  userManagementForm: FormGroup;
  categoryArr: any=[];
  totalRecord: any;
  limit: any=10;
  pageSize:number=10
  itemsPerPage: number = 5

  constructor( private route: Router, public service: ServerService) { }

  ngOnInit() {
   
    this.getUserList()
    this.userForm()
    this.searchCategory()
  }

  userForm(){
    this.userManagementForm = new FormGroup({
      'firstName':  new FormControl('', Validators.compose([ Validators.required, Validators.minLength(2), Validators.pattern(/^[^\s][a-zA-Z]*$/) ])),
      'lastName': new FormControl('', Validators.compose([ Validators.required, Validators.minLength(2), Validators.pattern(/^[^\s][a-zA-Z\s]*$/) ])),
      'city': new FormControl('', Validators.required),
      'state': new FormControl('', Validators.required),
      'category': new FormControl('', Validators.required), 
      'mobile': new FormControl( '', Validators.compose([Validators.required, Validators.pattern(/^[1-9][0-9]{7,15}$/)])),
    })
   }

  getUserList(){
    this.userList=[]
    this.totalRecord = 0
    let data = {
    'page':this.pageNumber,
    'limit':this.limit
    }
    this.service.showSpinner()
    this.service.postApi(`api/v1/admin/userList`,data).subscribe(res=>{
      this.service.hideSpinner()
      if(res.responseCode == 200){
        this.userList = res.result.docs
        this.service.toasterSucc(res.responseMessage)
        this.totalRecord = res.result.total
        console.log(this.totalRecord)
        this.limit = res.result.limit
      }else {
        this.service.toasterErr(res.responseMessage)
      } 
    },err=>{
      this.service.hideSpinner()
    })
  }

  // ------------------------pagination -------------------------//
  pagination(page){
    this.pageNumber=page;
    this.getUserList()   
  }
 

  searchUser(){
    this.userList=[]
    this.totalRecord = 0
    this.pageNumber=1
    let data ={
      'search': this.userManagementForm.value.firstName || this.userManagementForm.value.lastName || this.userManagementForm.value.state || this.userManagementForm.value.city || this.userManagementForm.value.mobile,
      'category': this.userManagementForm.value.category
    }
    this.service.showSpinner()
    this.service.postApi(`api/v1/admin/userList`,data).subscribe(res=>{
      this.service.hideSpinner()
      if(res.responseCode == 200){
        this.userList = res.result.docs
        this.service.toasterSucc(res.responseMessage)
        this.totalRecord = res.result.total
        this.limit = res.result.limit
      }else {
        this.service.toasterErr(res['responseMessage'])
      } 
    },err=>{
      this.service.hideSpinner()
    })
  }

  
  //========modal=======//
  delete(id: number) {
    this.userid = id;
    $('#deleteModal').modal('show')
  }
  //------------------------------delete api integration ----------------------------------//
  deleteUser() {
    
    var url = 'api/v1/admin/deleteUser/'+this.userid
    this.service.deleteApi(url).subscribe((res: any) => {
     
      if (res['responseCode'] = 200) {
        $('#deleteModal').modal('hide')
        this.service.toasterSucc('User removed');
        this.getUserList()
      }
     }, err => {   
       this.service.hideSpinner();  
        if (err['responseCode'] == '401') {  
            this.service.onLogout();   
           this.service.toasterErr('Unauthorized Access'); 
         } 
      else {    
          this.service.toasterErr('Something Went Wrong');  
        } 
     })

  }

    //-------------------------block api integration------------------------//
    block(status , id){   
       this.userid=id 
         this.userstatus=status 
      $('#block').modal('show')
    } 
     blockUser(){
       this.service.showSpinner();
      var url = 'api/v1/admin/blockUnblockUser'
      let data={
        'userId': this.userid
      }
         this.service.post(url,data).subscribe((res:any)=>{    
          if(res['responseCode'] == 200){ 
          this.service.hideSpinner()
             if (this.action == 'BLOCK') {
            $('#block').modal('hide');
            this.service.toasterSucc('User Blocked Successfully');
            this.getUserList()
          }
          else {
            $('#active').modal('hide');
            this.service.toasterSucc('User Activated Successfully');
            this.getUserList()
          }
       
            } 
       }, err => {   
           this.service.hideSpinner();  
          if (err['responseCode'] == '401') {  
              this.service.onLogout();   
             this.service.toasterErr('Unauthorized Access'); 
           } 
        else {    
            this.service.toasterErr('Something Went Wrong');  
          } 
       })
    } 
  

  openModal(action, userId) {
    this.userid = userId;
    this.action = action;
    console.log('modalcalled')
    if (action == 'DELETE') {
      $('#deleteModal').modal('show')

    } else if (action == 'BLOCK') {
      $('#block').modal('show')
    }
    else {
      $('#active').modal('show')
    }
  }

   //----------------------------------export User---------------------------------//
   exportAsXLSX() {
    let dataArr = [];
    this.userList.forEach((element, ind) => {
      let obj ={}
      obj={
        "S no": ind + 1,
        "Participant ID No": element.participantId ? element.participantId : 'N/A',
        "User Name": `${element.firstName}' '${element.lastName}`,
        "Mobile NO": element.mobileNumber ? element.mobileNumber : 'N/A',
        "Gender": element.gender ? element.gender : 'N/A',
        "city" : element.city ? element.city : 'N/A',
        "state" : element.state ? element.state: 'N/A',
        "History" : element.history ? element.history : 'N/A',
        "Facility Name" : element.facilityName ? element.facilityName : 'N/A',
        "categories" : element.categories ? element.categories.join(',') : 'N/A',
      }
      dataArr.push(obj)
    })

    this.service.exportAsExcelFile(dataArr, 'Admin User List');
  }
  navigateTo(path,item){
    localStorage.setItem('userObj',JSON.stringify(item))
    this.route.navigate([path,item._id])
  }
  //----------------reset --------------//
  resetForm(){
    this.userForm()
    this.getUserList()
  }
  //==========category List==============//
  categoryList(){
    this.service.postApi(`api/v1/admin/categoryList`,{}).subscribe(res=>{
      if(res.responseCode == 200){
        this.categoryArr = res.result.docs
      }
    },err=>{

    })
  }
  searchCategory(){
    this.service.get(`api/v1/admin/allCategoryList`).subscribe(res=>{
      if(res.responseCode == 200){
        this.categoryArr = res.result
      }
    },err=>{

    })
  }
}
